"use strict";

angular.module("informaApp")
    .service("DrugIndicationStatusEnum", function () {
        return {
            failed: 0,
            succeeded: 1,
            inProgress: 2,
            toBool: function (value) {
                return value === this.inProgress ? null : (value === this.succeeded);
            }
        }
    });